import { DeformSlotDisplayData } from "./DeformSlotDisplayData";
/**
 * @internal
 */
export class DeformSlotData {

	deformSlotDisplayList: DeformSlotDisplayData[] = [];

	//TODO:coverage
	constructor() {

	}

}


